// src/pages/BlogPage.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../firebase';

// Import the hero image
import heroImage from '../assets/images/blogbanner.png'; // Update the path as necessary

const BlogPage = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch articles from Firestore
    const fetchArticles = async () => {
      try {
        const q = query(collection(db, 'Blogs'), orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);
        const articlesData = [];
        querySnapshot.forEach((doc) => {
          articlesData.push({ id: doc.id, ...doc.data() });
        });
        setArticles(articlesData);
      } catch (error) {
        console.error('Error fetching articles:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchArticles();
  }, []);

  if (loading) {
    return <div className="text-center py-16">Loading articles...</div>;
  }

  return (
    <div>
      {/* Hero Section */}
      <div>
        <img
          src={heroImage}
          alt="Blog Hero"
          className="w-full h-auto sm:h-64 md:h-80 lg:h-[500px] object-cover"
        />
      </div>

      {/* Articles Section */}
      <div className="max-w-screen-xl mx-auto px-6 py-12">
        <h1 className="text-4xl font-bold mb-8 text-center">
          Latest <span className="text-yellow-500">Articles</span>
        </h1>
        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {articles.map((article) => (
            <div
              key={article.id}
              className="bg-white rounded-lg shadow-md overflow-hidden"
            >
              <img
                src={article.coverImageUrl}
                alt={article.title}
                className="w-full h-48 object-cover"
                loading="lazy"
              />
              <div className="p-6">
                <h2 className="text-xl font-semibold mb-2">{article.title}</h2>
                <p className="text-gray-600 mb-4">{article.subtitle}</p>
                <Link
                  to={`/articles/${article.slug}`}
                  className="text-yellow-500 font-semibold hover:underline"
                >
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
