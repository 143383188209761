// src/components/CardsSection.js
import React from 'react';
import Card from './Card';

// Import your SVG icons
import myIcon1 from '../assets/images/company/recipts10.svg';
import myIcon2 from '../assets/images/company/Groceries.svg';
import myIcon3 from '../assets/images/company/Recipes.svg';

const CardsSection = () => {
  return (
    <div className="flex flex-wrap justify-center gap-20 mt-10">
      <Card
        icon={<img src={myIcon1} alt="Feature 1" className="w-6 h-6" />}
        bgColor="#28a745"
        title="Scan Receipts"
        description="Instantly analyze your grocery receipts to uncover detailed nutritional information and healthier eating options."
      />
      <Card
        icon={<img src={myIcon2} alt="Feature 2" className="w-6 h-6" />}
        bgColor="#28a745"
        title="Craft Recipes"
        description="Generate personalized recipes based on your purchases, tailored to boost your nutrition and suit your taste."
      />
      <Card
        icon={<img src={myIcon3} alt="Feature 3" className="w-6 h-6" />}
        bgColor="#28a745"
        title="Menu Insights"
        description="Scan restaurant menus to get immediate insights into meal nutrition, helping you make smarter dining choices."
      />
    </div>
  );
};

export default CardsSection;

