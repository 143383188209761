// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    // apiKey: 'AIzaSyDIr4NoLRpbXWIqIdufHVmURkWO9ufhYwE',
    // appId: '1:990084350730:web:1001f2392ca488615df0f3',
    // messagingSenderId: '990084350730',
    // projectId: 'eatnice-98952',
    // authDomain: 'eatnice-98952.firebaseapp.com',
    // storageBucket: 'eatnice-98952.firebasestorage.app',
    // measurementId: 'G-9TVS827Y4S',


    apiKey: 'AIzaSyANKLIQJIuiCipwSMNb8s2lIxo5tRYnRMw',
    appId: '1:1032893402964:web:db734e3ac0d0320bec42c1',
    messagingSenderId: '1032893402964',
    projectId: 'journeyinsight',
    authDomain: 'journeyinsight.firebaseapp.com',
    storageBucket: 'journeyinsight.appspot.com',
    measurementId: 'G-LTVTJW759B',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
