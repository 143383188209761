// src/components/ArticleDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs, limit } from 'firebase/firestore';
import { db } from '../firebase';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import DOMPurify from 'dompurify';

const ArticleDetail = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the article with the matching slug
    const fetchArticle = async () => {
      try {
        const q = query(
          collection(db, 'Blogs'),
          where('slug', '==', slug),
          limit(1)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          setArticle({ id: doc.id, ...doc.data() });
        } else {
          console.error('No article found with the given slug.');
        }
      } catch (error) {
        console.error('Error fetching article:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [slug]);

  if (loading) {
    return <div className="text-center py-16">Loading article...</div>;
  }

  if (!article) {
    return <div className="text-center py-16">Article not found.</div>;
  }

  // Parse and render the description
  let articleContent = '';

  try {
    // Parse the JSON string to get the Delta operations
    const deltaOps = JSON.parse(article.description);

    // Create a converter instance
    const converter = new QuillDeltaToHtmlConverter(deltaOps, {
      customTagAttributes: (op) => {
        if (op.insert.type === 'video') {
          return {
            frameborder: '0',
            allowfullscreen: true,
            width: '560',
            height: '315',
          };
        }
        return {};
      },
    });

    // Convert the Delta to HTML
    articleContent = converter.convert();

    // Sanitize the HTML to prevent XSS attacks
    articleContent = DOMPurify.sanitize(articleContent, {
      ADD_TAGS: ['iframe'],
      ADD_ATTR: [
        'allow',
        'allowfullscreen',
        'frameborder',
        'src',
        'width',
        'height',
        'style',
      ],
      ALLOWED_URI_REGEXP: /^(https?:)?\/\/(www\.youtube\.com|youtube\.com|youtu\.be|player\.vimeo\.com)\//,
    });
  } catch (error) {
    console.error('Error parsing article description:', error);
    articleContent = '<p>Error displaying content.</p>';
  }

  return (
    <div className="max-w-screen-lg mx-auto px-4 sm:px-6 py-12 overflow-x-hidden">
      <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4">
        {article.title}
      </h1>
      <p className="text-lg sm:text-xl text-gray-600 mb-6">
        {article.subtitle}
      </p>
      <img
        src={article.coverImageUrl}
        alt={article.title}
        className="w-full h-auto mb-6"
      />
      {/* Render the article content */}
      <div className="prose max-w-none mx-auto">
        <div dangerouslySetInnerHTML={{ __html: articleContent }} />
      </div>
    </div>
  );
};

export default ArticleDetail;


// diettip.png
// spendingtracker.png
// scanreceipt.png
// analyze.png