// src/pages/TermsOfServicePage.js
import React from 'react';

const TermsOfServicePage = () => {
  return (
    <div className="bg-gray-50 py-12">
      <div className="max-w-screen-md mx-auto px-6">
        <h1 className="text-4xl font-bold mb-8 text-center text-darken">
          Terms of Service
        </h1>
        <div className="prose prose-lg text-gray-700">
          <p><strong>Last updated: 06 Aug 2020</strong></p>

          <p>
            Please read these Terms and Conditions carefully before using the EatNice app ("Service").
          </p>

          <p>
            Your access to and use of the EatNice app is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service.
          </p>

          <p>
            By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.
          </p>

          <p>
            EatNice reserves the right to change or update these Terms, or any other of our policies or practices, at any time, and will notify users by posting such changed or updated Terms on this page. Any changes or updates will be effective immediately upon posting.
          </p>

          <h2>Service Access and Changes</h2>
          <p>
            We reserve the right to withdraw or amend this Service or materials we provide in connection with the Service, at our sole discretion. We will not be liable if for any reason all or any part of the Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Service or the entire Service to users. We also may update the content on this Service from time to time, and we make reasonable efforts to maintain current information. However, we cannot guarantee that the content is complete or up-to-date.
          </p>

          <h2>Intended for Users Over 13</h2>
          <p>
            The Service is not for use by individuals under the age of 13. We will not knowingly collect information or provide Services to anyone under the age of 13.
          </p>

          <h2>Purchases</h2>
          <p>
            To access some parts of the Service, you are required to make a purchase (In-app purchase). Once purchased, you will be able to access additional features that EatNice provides. Purchases are refundable at EatNice's sole discretion.
          </p>

          <h2>Subscriptions</h2>
          <p>
            The mobile application offers subscriptions that give you access to additional features like unlimited entries, color themes, and others. Our subscriptions include a free trial period, where you can experience the mobile application at no cost. Subscription with the free trial period will automatically renew to a paid subscription once your free trial expires. If you decide to unsubscribe from a paid subscription before we start charging your payment method, cancel the subscription before the free trial ends.
          </p>

          <p>
            We offer a monthly subscription option. Payment will be charged to your credit/debit card through your iTunes Account or Google Play after you choose a subscription and confirm your purchase. Subscription automatically renews unless auto-renew is turned off, until cancelled in the Manage Subscriptions section of your account settings. We will notify you if the price of the subscription increases and, if required, seek your consent to continue. You will be charged no more than 24 hours prior to the start of the latest paid subscription period.
          </p>

          <h2>Changes</h2>
          <p>
            We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 15 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
          </p>

          <h2>Warranty Disclaimer</h2>
          <p>
            The Company controls and operates the App from various locations and makes no representation that the App is appropriate or available for use in all locations. The App or certain features of it may not be available in your location or may vary across locations.
          </p>

          <p>
            <strong>
              THE APP IS PROVIDED “AS IS”, “AS AVAILABLE” AND IS PROVIDED WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED, SAVE TO THE EXTENT REQUIRED BY LAW. THE COMPANY AND ITS DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, SUPPLIERS, PARTNERS, AND CONTENT PROVIDERS DO NOT WARRANT THAT:
            </strong>
          </p>

          <ol className="list-decimal list-inside">
            <li>The App will be secure or available at any particular time or location;</li>
            <li>Any defects or errors will be corrected;</li>
            <li>Any content or software available at or through the App is free of viruses or other harmful components;</li>
            <li>The outcomes of using the App will meet your requirements.</li>
          </ol>

          <p>
            Your use of the App is solely at your own risk. Some states or countries do not allow limitations on implied warranties, so the above limitations may not apply to you.
          </p>

          <h2>Limitation of Liability</h2>
          <p>
            IN NO EVENT SHALL THE COMPANY, ITS OFFICERS, DIRECTORS, AGENTS, AFFILIATES, EMPLOYEES, ADVERTISERS, OR DATA PROVIDERS BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES (INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS, OR LOSS OF DATA) WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE), EQUITY, OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF THIS APP. IN NO EVENT WILL THE COMPANY’S TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE APP EXCEED THE AMOUNTS YOU HAVE PAID TO THE COMPANY FOR USE OF THE APP IF YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS TO THE COMPANY, AS APPLICABLE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. THE COMPANY OR ANY THIRD PARTIES MENTIONED ON THE APP ARE NOT LIABLE FOR ANY PERSONAL INJURY, INCLUDING DEATH, CAUSED BY YOUR USE OR MISUSE OF THE APP.
          </p>

          <h2>Use of Mobile Devices</h2>
          <p>
            Please note that your carrier’s normal rates and fees, such as text messaging and data charges, will still apply if you are using the App on a mobile device.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfServicePage;
