// import React, { useState } from 'react';

// const Navbar = () => {
//   const [open, setOpen] = useState(false);

//   return (
//     <div className="w-full text-gray-700 bg-cream">
//       <div className="flex flex-col max-w-screen-xl px-8 mx-auto md:items-center md:justify-between md:flex-row">
//         <div className="flex flex-row items-center justify-between py-6">
//           <div className="relative md:mt-8">
//             <a
//               href="#"
//               className="text-lg relative z-50 font-bold tracking-widest text-gray-900 rounded-lg focus:outline-none focus:shadow-outline"
//             >
//               EATNICE
//             </a>
//             {/* <svg
//               className="h-11 z-40 absolute -top-2 -left-3"
//               viewBox="0 0 79 79"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 d="M35.2574 2.24264C37.6005 -0.100501 41.3995 -0.100505 43.7426 2.24264L76.7574 35.2574C79.1005 37.6005 79.1005 41.3995 76.7574 43.7426L43.7426 76.7574C41.3995 79.1005 37.6005 79.1005 35.2574 76.7574L2.24264 43.7426C-0.100501 41.3995 -0.100505 37.6005 2.24264 35.2574L35.2574 2.24264Z"
//                 fill="#65DAFF"
//               />
//             </svg> */}
//           </div>
//           <button
//             className="rounded-lg md:hidden focus:outline-none focus:shadow-outline"
//             onClick={() => setOpen(!open)}
//           >
//             {/* <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
//               {!open ? (
//                 <path
//                   fillRule="evenodd"
//                   d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
//                   clipRule="evenodd"
//                 />
//               ) : (
//                 <path
//                   fillRule="evenodd"
//                   d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
//                   clipRule="evenodd"
//                 />
//               )}
//             </svg> */}
//           </button>
//         </div>
//         <nav
//           className={`${
//             open ? 'block' : 'hidden'
//           } md:flex flex-col flex-grow md:items-center pb-4 md:pb-0 md:flex-row origin-top duration-300`}
//         >
//           <a
//             className="px-4 py-2 mt-2 text-sm bg-transparent rounded-lg md:mt-8 md:ml-4 hover:text-gray-900 focus:outline-none focus:shadow-outline"
//             href="#"
//           >
//             Home
//           </a>
//           <a
//             className="px-4 py-2 mt-2 text-sm bg-transparent rounded-lg md:mt-8 md:ml-4 hover:text-gray-900 focus:outline-none focus:shadow-outline"
//             href="#"
//           >
//             Careers
//           </a>
//           <a
//             className="px-4 py-2 mt-2 text-sm bg-transparent rounded-lg md:mt-8 md:ml-4 hover:text-gray-900 focus:outline-none focus:shadow-outline"
//             href="#"
//           >
//             Blog
//           </a>
//           <a
//             className="px-4 py-2 mt-2 text-sm bg-transparent rounded-lg md:mt-8 md:ml-4 hover:text-gray-900 focus:outline-none focus:shadow-outline"
//             href="#"
//           >
//             About Us
//           </a>
//           <a
//             className="px-10 py-3 mt-2 text-sm text-center bg-white text-gray-800 rounded-full md:mt-8 md:ml-4"
//             href="#"
//           >
//             Login
//           </a>
//           <a
//             className="px-10 py-3 mt-2 text-sm text-center bg-yellow-500 text-white rounded-full md:mt-8 md:ml-4"
//             href="#"
//           >
//             Sign Up
//           </a>
//         </nav>
//       </div>
//     </div>
//   );
// };

// export default Navbar;



// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logoImage from '../assets/images/logo2.png';

const Navbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <nav className="w-full bg-cream text-gray-700">
      <div className="max-w-screen-xl mx-auto px-8 flex items-center justify-between py-4">
        {/* Left Section - Logo */}
        <div className="flex items-center">
          <Link to="/">
            <img src={logoImage} alt="Logo" className="h-10 w-auto" />
          </Link>
        </div>

        {/* Center Section - Navigation Links */}
        <div className="hidden md:flex space-x-6">
          <Link
            to="/"
            className="px-3 py-2 text-md font-medium hover:text-gray-900 focus:outline-none focus:shadow-outline"
          >
            Home
          </Link>
         
          <Link
            to="/blog"
            className="px-3 py-2 text-md font-medium hover:text-gray-900 focus:outline-none focus:shadow-outline"
          >
            Blog
          </Link>
          <Link
            to="/contact-us"
            className="px-3 py-2 text-md font-medium hover:text-gray-900 focus:outline-none focus:shadow-outline"
          >
            Contact Us
          </Link>
        </div>

        {/* Right Section - Download Button */}
        <div className="hidden md:flex items-center space-x-4">
          <Link
            to="/download"
            className="px-6 py-2 text-lg font-medium text-white bg-yellow-500 rounded-full focus:outline-none focus:shadow-outline"
          >
            Download
          </Link>
        </div>

        {/* Mobile menu button */}
        <div className="md:hidden">
          <button
            onClick={() => setOpen(!open)}
            className="text-gray-800 focus:outline-none focus:shadow-outline"
          >
            {/* Icon */}
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              {open ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {open && (
        <div className="md:hidden bg-cream">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {/* Navigation Links */}
            <Link
              to="/"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-100"
              onClick={() => setOpen(false)}
            >
              Home
            </Link>
           
            <Link
              to="/blog"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-100"
              onClick={() => setOpen(false)}
            >
              Blog
            </Link>
            <Link
              to="/contact-us"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-100"
              onClick={() => setOpen(false)}
            >
              Contact Us
            </Link>

            {/* Download Button */}
            <div className="mt-4 flex flex-col space-y-2">
              <Link
                to="/download"
                className="block text-center px-3 py-2 rounded-full text-base font-medium text-white bg-yellow-500 focus:outline-none focus:shadow-outline"
                onClick={() => setOpen(false)}
              >
                Download
              </Link>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;


