// import React from 'react';

// const Card = ({ icon, bgColor, title, description }) => {
//   return (
//     <div data-aos="fade-up" className="bg-white shadow-xl p-6 text-center rounded-xl">
//       <div
//         style={{ background: bgColor }}
//         className="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-12"
//       >
//         {icon}
//       </div>
//       <h1 className="font-medium text-xl mb-3 lg:px-14 text-darken">{title}</h1>
//       <p className="px-4 text-gray-500">{description}</p>
//     </div>
//   );
// };

// export default Card;


// src/components/Card.js
// src/components/Card.js
import React from 'react';

const Card = ({ icon, bgColor, title, description }) => {
  return (
    <div
      data-aos="fade-up"
      className="bg-white shadow-lg p-4 text-center rounded-lg w-80 h-60 flex flex-col items-center"
    >
      <div
        style={{ backgroundColor: bgColor }}
        className="rounded-full w-12 h-12 flex items-center justify-center shadow-md transform -translate-y-6"
      >
        {icon}
      </div>
      <h1 className="font-medium text-lg mb-2 text-darken">{title}</h1>
      <p className="px-2 text-gray-500 flex-grow">{description}</p>
    </div>
  );
};

export default Card;


