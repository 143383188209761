// // src/components/LatestNews.js
// import React, { useRef, useState, useEffect } from 'react';
// import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'; // Importing arrow icons
// import laptopNewsImage from '../assets/images/laptop-news.png';
// import childrenLaptopImage from '../assets/images/children-laptop.png';
// import girlLaptopImage from '../assets/images/girl-laptop.png';
// import catLaptopImage from '../assets/images/cat-laptop.png';

// const LatestNews = () => {
//   const carouselRef = useRef(null);
//   const [currentPage, setCurrentPage] = useState(0);

//   const cardsPerView = 5; // Number of cards visible at once
//   const totalCards = 6; // Total number of articles
//   const totalPages = Math.ceil(totalCards / cardsPerView);

//   const newsArticles = [
//     {
//       id: 1,
//       image: laptopNewsImage,
//       title: 'Revolutionizing Education with Technology',
//       excerpt:
//         'Discover how EatNice is transforming the educational landscape with innovative tech solutions...',
//       link: '/articles/revolutionizing-education',
//     },
//     {
//       id: 2,
//       image: childrenLaptopImage,
//       title: 'Engaging Young Minds',
//       excerpt:
//         'Learn about our strategies to keep children engaged and excited about learning...',
//       link: '/articles/engaging-young-minds',
//     },
//     {
//       id: 3,
//       image: girlLaptopImage,
//       title: 'Personalized Learning Experiences',
//       excerpt:
//         'Explore how personalized learning paths are enhancing student success rates...',
//       link: '/articles/personalized-learning',
//     },
//     {
//       id: 4,
//       image: catLaptopImage,
//       title: 'Integrating Fun into Education',
//       excerpt:
//         'Find out how integrating fun activities can boost retention and understanding...',
//       link: '/articles/integrating-fun',
//     },
//     {
//       id: 5,
//       image: catLaptopImage,
//       title: 'Healthy Eating Habits for Students',
//       excerpt:
//         'Tips and strategies for promoting healthy eating habits among students...',
//       link: '/articles/healthy-eating-habits',
//     },
//     {
//       id: 6,
//       image: catLaptopImage,
//       title: 'The Future of E-Learning',
//       excerpt:
//         'A look into the future trends of e-learning and digital education...',
//       link: '/articles/future-of-e-learning',
//     },
//     // Add more articles as needed
//   ];

//   // Scroll Left Function
//   const scrollLeft = () => {
//     if (carouselRef.current) {
//       const newPage = currentPage > 0 ? currentPage - 1 : totalPages - 1;
//       const scrollAmount = carouselRef.current.clientWidth * newPage;
//       carouselRef.current.scrollTo({
//         left: scrollAmount,
//         behavior: 'smooth',
//       });
//       setCurrentPage(newPage);
//     }
//   };

//   // Scroll Right Function
//   const scrollRight = () => {
//     if (carouselRef.current) {
//       const newPage = currentPage < totalPages - 1 ? currentPage + 1 : 0;
//       const scrollAmount = carouselRef.current.clientWidth * newPage;
//       carouselRef.current.scrollTo({
//         left: scrollAmount,
//         behavior: 'smooth',
//       });
//       setCurrentPage(newPage);
//     }
//   };

//   // Handle Scroll Event to Update Current Page
//   const handleScroll = () => {
//     if (carouselRef.current) {
//       const scrollLeftPos = carouselRef.current.scrollLeft;
//       const page = Math.round(scrollLeftPos / carouselRef.current.clientWidth);
//       setCurrentPage(page);
//     }
//   };

//   // Add Scroll Event Listener
//   useEffect(() => {
//     const carousel = carouselRef.current;
//     if (carousel) {
//       carousel.addEventListener('scroll', handleScroll);
//       return () => {
//         carousel.removeEventListener('scroll', handleScroll);
//       };
//     }
//   }, []);

//   return (
//     <section className="py-16 bg-gray-100">
//       <div className="max-w-screen-2xl mx-auto px-6 sm:px-12 lg:px-16">
//         {/* Section Heading */}
//         <h2 className="text-3xl lg:text-4xl font-semibold text-center text-darken mb-12">
//           Latest <span className="text-yellow-500">Articles</span>
//         </h2>

//         {/* Carousel Container */}
//         <div className="relative">
//           {/* Scroll Buttons */}
//           <button
//             onClick={scrollLeft}
//             className="absolute top-1/2 left-[-80px] transform -translate-y-1/2 bg-white bg-opacity-75 text-green-500 p-4 rounded-full hover:bg-opacity-100 focus:outline-none z-20 shadow-lg transition-opacity duration-300"
//             aria-label="Scroll Left"
//           >
//             <FaArrowLeft size={30} />
//           </button>
//           <button
//             onClick={scrollRight}
//             className="absolute top-1/2 right-[-80px] transform -translate-y-1/2 bg-white bg-opacity-75 text-green-500 p-4 rounded-full hover:bg-opacity-100 focus:outline-none z-20 shadow-lg transition-opacity duration-300"
//             aria-label="Scroll Right"
//           >
//             <FaArrowRight size={30} />
//           </button>

//           {/* Carousel */}
//           <div
//             ref={carouselRef}
//             className="flex space-x-6 overflow-x-auto scroll-smooth snap-x snap-mandatory scrollbar-hide"
//           >
//             {newsArticles.map((article) => (
//               <div
//                 key={article.id}
//                 className="flex-none w-80 bg-white rounded-lg shadow-md overflow-hidden snap-start"
//               >
//                 <img
//                   src={article.image}
//                   alt={article.title}
//                   className="w-full h-48 object-cover"
//                   loading="lazy"
//                 />
//                 <div className="p-6">
//                   <h3 className="text-xl font-semibold mb-2">{article.title}</h3>
//                   <p className="text-gray-600 mb-4">{article.excerpt}</p>
//                   <a
//                     href={article.link}
//                     className="text-yellow-500 font-semibold hover:underline"
//                   >
//                     Read More
//                   </a>
//                 </div>
//               </div>
//             ))}
//           </div>

//           {/* Dot Pagination */}
//           <div className="flex justify-center mt-6 space-x-2">
//             {Array.from({ length: totalPages }).map((_, index) => (
//               <button
//                 key={index}
//                 className={`w-3 h-3 rounded-full ${
//                   currentPage === index ? 'bg-green-500' : 'bg-gray-300'
//                 }`}
//                 onClick={() => {
//                   const scrollAmount = carouselRef.current.clientWidth * index;
//                   carouselRef.current.scrollTo({
//                     left: scrollAmount,
//                     behavior: 'smooth',
//                   });
//                   setCurrentPage(index);
//                 }}
//                 aria-label={`Go to page ${index + 1}`}
//               ></button>
//             ))}
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default LatestNews;



// src/components/LatestNews.js
import React, { useRef, useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the path if necessary

const LatestNews = () => {
  const carouselRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [newsArticles, setNewsArticles] = useState([]);
  const cardsPerView = 5;

  // Fetch data from Firestore
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const q = query(collection(db, 'Blogs'), orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);
        const articles = [];
        querySnapshot.forEach((doc) => {
          articles.push({ id: doc.id, ...doc.data() });
        });
        setNewsArticles(articles);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };
    fetchArticles();
  }, []);

  const totalPages = Math.ceil(newsArticles.length / cardsPerView);

  // Scroll Functions and Event Listeners remain the same...

  return (
    <section className="py-16 bg-gray-100">
      <div className="max-w-screen-2xl mx-auto px-6 sm:px-12 lg:px-16">
        <h2 className="text-3xl lg:text-4xl font-semibold text-center text-darken mb-12">
          Latest <span className="text-yellow-500">Articles</span>
        </h2>
        <div className="relative">
          {/* Scroll Buttons */}
          {/* ... */}
          {/* Carousel */}
          <div
            ref={carouselRef}
            className="flex space-x-6 overflow-x-auto scroll-smooth snap-x snap-mandatory scrollbar-hide"
          >
            {newsArticles.map((article) => (
              <div
                key={article.id}
                className="flex-none w-80 bg-white rounded-lg shadow-md overflow-hidden snap-start"
              >
                <img
                  src={article.coverImageUrl}
                  alt={article.title}
                  className="w-full h-48 object-cover"
                  loading="lazy"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">{article.title}</h3>
                  <p className="text-gray-600 mb-4">{article.subtitle}</p>
                  <a
                    href={`/articles/${article.slug}`}
                    className="text-yellow-500 font-semibold hover:underline"
                  >
                    Read More
                  </a>
                </div>
              </div>
            ))}
          </div>
          {/* Dot Pagination */}
          {/* ... */}
        </div>
      </div>
    </section>
  );
};

export default LatestNews;

