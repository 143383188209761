// src/pages/PrivacyPolicyPage.js
import React from 'react';

const PrivacyPolicyPage = () => {
  return (
    <div className="max-w-screen-lg mx-auto px-6 py-12">
      <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
      <div className="prose prose-lg text-gray-700">
        <p><strong>Last updated: 6 Aug 2020</strong></p>

        <p>
          We operate EatNice app ("Service"). This page informs you of our policies regarding the collection, use, and disclosure of Personal Information we receive from users of the Service. EatNice takes your privacy seriously, and we want you to know how we collect, use, share, and protect your information.
        </p>

        <p>
          We use your Personal Information only for providing and improving the Service. We will never abuse it or sell it to any third party.
        </p>

        <p>
          By using the Service, you agree to the collection and use of information in accordance with this policy.
        </p>

        <h2>Information Collection And Use</h2>
        <p>
          All of your data is stored only locally on your device unless you choose to enable data backup. Only then will your data be backed up on the EatNice server. While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your name and email ("Personal Information").
        </p>

        <h2>What We Do with Your Information</h2>
        <p>
          EatNice respects your privacy and will not sell your personal information to third parties. We may use your personal information to provide Services to you, respond to your inquiries, provide information on products and services you request, or have a representative contact you regarding our products or services. Unless you have otherwise opted out of receiving email communication from us, you agree by using the App or Services to allow us to use your email for communication and provision of Services consistent with this Privacy Policy.
        </p>

        <p>
          We may also use your personal information to update you on special offers related to our products or services, improve our products and services, provide product announcements or information regarding health topics, deliver other information we believe you will find most relevant and useful, and in any other way we may describe when you provide the information or to which you consent.
        </p>

        <p>
          We may occasionally contact you to gather customer service information to help us determine how we can improve our services and products to better meet your needs. We may also de-identify and/or aggregate your data for various business purposes, including product, service, and program development and improvement.
        </p>

        <p>
          We use the general information we collect from you to help us understand and analyze users of our App, including generating aggregate statistics about App use. This data can then be used to tailor this App’s content, deliver a better experience for our users. We may also collect, aggregate, and maintain anonymous information about the visitors to our App. We may further share such aggregate, non-identifiable information with third parties.
        </p>

        <h2>How to Opt Out</h2>
        <p>
          To opt out, please do not provide your personal information to us. All of your data is stored only locally on your device unless you choose to enable data backup. Only then will your data be backed up on the EatNice server. After providing your personal information to us, please send written notification to us that you no longer wish to receive information and communications from us or otherwise share your personal information.
        </p>

        <p>
          With respect to the collection and use of general information, you have the ability to disable or manage the use of cookies on your computer using controls in your browser. However, you are not able to opt out of the uses of general information otherwise collected as set forth in this policy.
        </p>

        <h2>Log Data</h2>
        <p>
          Like many mobile applications, we collect information that your phone sends whenever you visit our Service ("Log Data"). This Log Data may include information such as your phone's Internet Protocol ("IP") address, phone model, operating system version, the parts of our Service that you use, the time and date you used them, the time spent on those parts, and other statistics.
        </p>

        <p>
          In addition, we may use third-party services such as Firebase that collect, monitor, and analyze this data for us.
        </p>

        <h2>Communications</h2>
        <p>
          We may use your Personal Information to contact you with newsletters, marketing or promotional materials, and other information that might be useful to you.
        </p>

        <h2>Security</h2>
        <p>
          The security of your Personal Information is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
        </p>

        <h2>Changes To This Privacy Policy</h2>
        <p>
          This Privacy Policy is effective as of 6 Aug 2020 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
