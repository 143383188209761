// src/components/HeroSection.js
import React from 'react';

// Import images and icons
import girlImage from '../assets/images/phone4.png';
import calendarImage from '../assets/floating/diettip.png';
import redSquareImage from '../assets/floating/dailyconsumption.png';
import uxClassImage from '../assets/floating/scanreceipt.png';
import congratsImage from '../assets/floating/spendingtracker.png';
import appStoreIcon from '../assets/images/company/appstor.svg'; // App Store icon SVG
import playStoreIcon from '../assets/images/company/playstor.svg'; // Play Store icon SVG

const HeroSection = () => {
  return (
    <div className="bg-cream">
      <div className="max-w-screen-xl px-8 mx-auto flex flex-col lg:flex-row items-start">
        {/* Left Column */}
        <div className="flex flex-col w-full lg:w-6/12 justify-center lg:pt-24 items-start text-center lg:text-left mb-5 md:mb-0">
          <h1
            data-aos="fade-right"
            data-aos-once="true"
            className="my-4 text-3xl sm:text-4xl md:text-5xl font-bold leading-tight text-darken"
          >
            <span className="text-yellow-500">Scan,</span> Analyze, Optimize Your Diet
          </h1>
          <p
            data-aos="fade-down"
            data-aos-once="true"
            data-aos-delay="300"
            className="leading-normal text-base sm:text-lg md:text-xl mb-8"
          >
            Explore nutri scores, get tailored exercise advice, and discover AI-generated recipes based on your purchases
          </p>
          {/* App Store and Play Store Icons */}
          <div
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-delay="700"
            className="flex space-x-4 mt-5 md:mt-0"
          >
            <a href="https://play.google.com" target="_blank" rel="noopener noreferrer">
              <img src={playStoreIcon} alt="Get it on Google Play" className="h-12" />
            </a>
            <a href="https://apps.apple.com" target="_blank" rel="noopener noreferrer">
              <img src={appStoreIcon} alt="Download on the App Store" className="h-12" />
            </a>
          </div>
        </div>
        {/* Right Column */}
        <div className="w-full lg:w-6/12 lg:-mt-10 relative" id="girl">
          <img
            data-aos="fade-up"
            data-aos-once="true"
            className="w-10/12 mx-auto 2xl:-mb-20"
            src={girlImage}
            alt="Girl"
          />
          {/* Floating Elements */}
          {/* Calendar */}
          {/* Floating Elements */}
          {/* Calendar */}
          <div
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-once="true"
            className="absolute top-20 -left-6 sm:top-32 sm:left-10 md:top-40 md:left-16 lg:-left-0 lg:top-52 floating-4"
          >
            <img
              className="bg-white bg-opacity-80 rounded-lg h-12 sm:h-16" // Updated size
              src={calendarImage}
              alt="Calendar"
            />
          </div>
          {/* Red Square */}
          <div
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-once="true"
            className="absolute top-14 -right-2 sm:right-2 sm:top-20 md:top-24 lg:-right-0 floating"
          >
            <img
              className="bg-white bg-opacity-80 rounded-lg h-12 sm:h-16" // Updated size
              src={redSquareImage}
              alt="Red Square"
            />
          </div>
          {/* UX Class */}
          <div
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-once="true"
            className="absolute bottom-14 -left-4 sm:left-2 sm:bottom-20 lg:bottom-24 lg:-left-0 floating"
          >
            <img
              className="bg-white bg-opacity-80 rounded-lg h-12 sm:h-16" // Updated size
              src={uxClassImage}
              alt="UX Class"
            />
          </div>
          {/* Congrats */}
          <div
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-once="true"
            className="absolute bottom-20 md:bottom-48 lg:bottom-52 -right-6 lg:right-8 floating-4"
          >
            <img
              className="bg-white bg-opacity-80 rounded-lg h-12 sm:h-16" // No change; already perfect
              src={congratsImage}
              alt="Congrats"
            />
          </div>

        </div>
      </div>
      {/* SVG Wave */}
      <div className="text-white -mt-14 sm:-mt-24 lg:-mt-36 z-40 relative">
        <svg
          className="xl:h-40 xl:w-full"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z"
            fill="currentColor"
          ></path>
        </svg>
        <div className="bg-white w-full h-20 -mt-px"></div>
      </div>
    </div>
  );
};

export default HeroSection;
