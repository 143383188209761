// 

import React from 'react';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import TrustedBy from '../components/TrustedBy';
import AllInOneSoftware from '../components/AllInOneSoftware';
import CardsSection from '../components/CardsSection';
import JoinCommunity from '../components/JoinCommunity';
import InstructorStudentCards from '../components/InstructorStudentCards';
import PhysicalClassroomSection from '../components/PhysicalClassroomSection';
import FeatureSections from '../components/FeatureSections';
import Integrations from '../components/Integrations';
import Testimonial from '../components/Testimonial';
import LatestNews from '../components/LatestNews';
import Footer from '../components/Footer';

const HomePage = () => {
  return (
    <div>
      <HeroSection />
      {/* <TrustedBy /> */}
      <AllInOneSoftware />
      <CardsSection />
      <PhysicalClassroomSection />
      <FeatureSections />
      <LatestNews />

      <JoinCommunity />

      {/* <InstructorStudentCards /> */}
      {/* <Integrations /> */}
      {/* <Testimonial /> */}
    </div>
  );
};

export default HomePage;
