import React from 'react';
import workflowImage from '../assets/images/mobile7.png';
// src/components/FeatureSections.js


const FeatureSections = () => {
  return (
    <section className="py-16 bg-white">
    <div className="max-w-screen-xl mx-auto px-6 sm:px-12 lg:px-24">
      {/* Section Heading */}
      <h2 className="text-3xl lg:text-4xl font-semibold text-center text-darken mb-12">
        <span className="text-yellow-500">Eatnice </span> workflow from <span className="text-yellow-500">scan to insights</span>
      </h2>

      <div className="flex flex-col lg:flex-row items-start">
        {/* Left Side - Image */}
        <div className="w-full lg:w-2/3 mb-10 lg:mb-0 flex justify-center">
          <img
            src={workflowImage}
            alt="Eatnice Workflow"
            className="w-full h-auto"
          />
        </div>

          {/* Right Side - Vertical Timeline */}
          <div className="w-full lg:w-1/2 relative">
            <div className="border-l-4 border-green-500 ml-4">
              {/* Timeline Item 1 */}
              <div className="mb-10 relative">
                <div className="absolute -left-5 top-0 w-10 h-10 bg-green-500 rounded-full flex items-center justify-center text-white font-bold">
                  1
                </div>
                <h3 className="ml-8 text-xl font-semibold text-darken mb-2">
                  Scan your receipt
                </h3>
                <p className="ml-8 text-gray-700">
                  Use the Eatnice app to quickly scan your grocery receipts.
                </p>
              </div>

              {/* Timeline Item 2 */}
              <div className="mb-10 relative">
                <div className="absolute -left-5 top-0 w-10 h-10 bg-green-500 rounded-full flex items-center justify-center text-white font-bold">
                  2
                </div>
                <h3 className="ml-8 text-xl font-semibold text-darken mb-2">
                  Detailed Nutriscore
                </h3>
                <p className="ml-8 text-gray-700">
                  Receive a comprehensive Nutriscore for your purchases.
                </p>
              </div>

              {/* Timeline Item 3 */}
              <div className="mb-10 relative">
                <div className="absolute -left-5 top-0 w-10 h-10 bg-green-500 rounded-full flex items-center justify-center text-white font-bold">
                  3
                </div>
                <h3 className="ml-8 text-xl font-semibold text-darken mb-2">
                  Detailed Nutrifacts
                </h3>
                <p className="ml-8 text-gray-700">
                  Access detailed nutritional facts about the items you bought.
                </p>
              </div>

              {/* Timeline Item 4 */}
              <div className="mb-10 relative">
                <div className="absolute -left-5 top-0 w-10 h-10 bg-green-500 rounded-full flex items-center justify-center text-white font-bold">
                  4
                </div>
                <h3 className="ml-8 text-xl font-semibold text-darken mb-2">
                  Diet Tips
                </h3>
                <p className="ml-8 text-gray-700">
                  Get personalized diet tips based on your purchase history.
                </p>
              </div>

              {/* Timeline Item 5 */}
              <div className="mb-10 relative">
                <div className="absolute -left-5 top-0 w-10 h-10 bg-green-500 rounded-full flex items-center justify-center text-white font-bold">
                  5
                </div>
                <h3 className="ml-8 text-xl font-semibold text-darken mb-2">
                  Healthy Recommendations
                </h3>
                <p className="ml-8 text-gray-700">
                  Receive recommendations for healthier alternatives.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureSections;

